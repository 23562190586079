import React from 'react'

const List = (props) => {

    const items = []
    
    for(let [idx, val] of props.items.entries()) {
        items.push(<ListItem name={val} id={idx} key={idx}/>)        
    }

    return (
        <ul className="list-root">
            {items}
        </ul>
    );
}

const ListItem = (props) => {
    return (
        <li className="list-item" key={props.id}>
            <div className="icon is-small mr-3"><svg className="arrow-svg" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><g><rect fill="none" height="24" width="24"/></g><g><g><polygon points="15.5,5 11,5 16,12 11,19 15.5,19 20.5,12"/><polygon points="8.5,5 4,5 9,12 4,19 8.5,19 13.5,12"/></g></g></svg></div>
            <div className="list-item-text">{props.name}</div>
        </li>
    );
}

const Skills = () => {
    
    return (
        <div className="is-dark">
            <div className="columns">
                <div className="column"></div>
                <div className="column">
                    <p className="subtitle is-5 has-text-primary-light has-text-justified pb-2">
                        Here are a few technologies I've been working with recently:
                    </p>
                    <div className="has-text-primary-light is-inline-flex">
                        <List items={['Java', 'Python', 'Javascript']}/>

                        <List items={['Spring', 'React', 'FastAPI']}/>

                        <List items={['Solr', 'Oracle Endeca', 'PostgreSQL']}/>                        
                    </div>
                </div>
                <div className="column"></div>
            </div>
        </div>
    )
}

export default Skills;
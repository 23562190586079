import './App.css';
import 'bulma/css/bulma.css'
import Header from './content/Header';
import Footer from './content/Footer';
import Intro from './content/Intro'
import About from './content/About';
import Experience from './content/Experience'
import Contact from './content/Contact';
import { Element } from 'react-scroll'

/** Reference fro single page application: https://www.digitalocean.com/community/tutorials/how-to-implement-smooth-scrolling-in-react */

function App() {
  return (
    <div className="App has-background-dark">
      <Header />
      <Element name="home">
        <Intro />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="experience">
        <Experience />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Footer />      
    </div>
  );
}

export default App;

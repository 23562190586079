import React from 'react'

const Intro = () => {

    return (
        <section className="hero is-dark is-large">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h5 className="subtitle is-5 has-text-primary-light">
                        Hi, my name is
                    </h5>
                    <h1 className="title is-1 has-text-primary">
                        Vijay Tambe,
                    </h1>
                    <h5 className="subtitle is-5 pt-4 has-text-primary-light">
                        and I work as a
                    </h5>
                    <h3 className="title is-3 has-text-primary">
                        Software Engineer.
                    </h3>
                    <a href="mailto:vijaydt89@gmail.com" className="button is-primary is-outlined">Get In Touch</a>
                </div>
            </div>
        </section>
    )
}

export default Intro;
import React from 'react'
import Skills from './Skills';

const About = () => {
    return (
        <section className="section is-dark is-medium">            
            <h1 className="title has-text-primary">
                - About me -
            </h1>
            <div className="columns">
                <div className="column"></div>
                <div className="column">
                    <p className="is-size-5 has-text-primary-light mb-2 has-text-justified">
                        Hello! My name is Vijay and I enjoy developing things that run on computers with or without the internet.
                    </p>

                    <p className="is-size-5 has-text-primary-light mb-2 has-text-justified">
                        I'm a Crawley, UK-based software developer who specialized in building web applications. Currently, I'm a developer at Akixi focused on building call management software that is helping businesses to improve their customer support services.
                    </p>
                </div>
                <div className="column"></div>
            </div>

            <Skills />
        </section>
    )
}

export default About;
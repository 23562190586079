import React from 'react'

const JobTile = (props) => {
    return (
        <div className="tile is-parent">
            <article className="tile is-child box">
                <h5 className="title is-5 has-text-primary-light">{props.companyName}</h5>
                <h6 className="subtitle is-6 has-text-primary-light">{props.period}</h6>
                <h4 className="subtitle is-4 has-text-primary-light">{props.title}</h4>
            </article>
        </div>
    );
}
const Experience = () => {
    return (
        <section className="section is-dark is-medium">            
            <h1 className="title has-text-primary">
                - Where I've Worked? -
            </h1>
            <div className="container">
                <p className="subtitle is-5 has-text-primary-light has-text-justified pb-2">
                    Here is the list of my work experince:
                </p>
                <div className="tile is-ancestor">
                    <JobTile companyName="Cybage Software Ltd." period="Nov,2011 - Sept,2013" title="Software Engineer"/>
                    <JobTile companyName="Amplifi Commerce" period="Sept,2013 - Apr,2014" title="Endeca Developer"/>
                    <JobTile companyName="TAIS Tech" period="May,2014 - Mar,2016" title="Sr. Software Engineer"/>
                </div>
                <div className="tile is-ancestor">
                    <JobTile companyName="Facet commerce Pvt Ltd" period="Apr,2016 - May,2017" title="Endeca ATG Consultant"/>
                    <JobTile companyName="Avenir Business Solutions" period="May, 2017 - May, 2020" title="Java Developer"/>
                    <JobTile companyName="Akixi Limited" period="Apr,2020 - Present" title="Sr. Software Engineer"/>
                </div>
            </div>            
        </section>
    )
}

export default Experience;
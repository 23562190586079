import React from 'react'

const Contact = () => {
    return (
        <section className="section is-dark is-medium">            
            <h1 className="title has-text-primary pt-6">
                - What's next? -
            </h1>
            <div className="columns pb-6 pt-2">
                <div className="column"></div>
                <div className="column">
                    <p className="is-size-5 has-text-primary-light mb-6 has-text-justified">
                        Although I'm not looking for new opportunities at the moment, my inbox is always open.
                        Whether you have a question or just want to say hi, I'll try my best to get back to you.
                    </p>
                    <a href="mailto:vijaydt89@gmail.com" className="button is-primary is-outlined pt-2">Contact</a>
                </div>
                <div className="column"></div>
            </div>

        </section>
    )
}

export default Contact;
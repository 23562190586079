import React, {useRef} from 'react'
import { Link } from 'react-scroll'

const ToggleMenu = () => {
    const burger = document.getElementsByClassName('navbar-burger');
    const menu = document.getElementsByClassName('navbar-menu');
    if (burger) {
        burger[0].classList.toggle('is-active');
    }

    if(menu) {
        menu[0].classList.toggle('is-active');
    }
}

const Header = () => {
    const ref = useRef(null);

    return (
        <header>            
            <nav className="navbar is-dark is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <div className="navbar-item">
                        <Link activeClass="test1" className="navbar-item has-text-primary-light" to="home" spy={true} smooth={true} duration={500}>
                            <span className="is-size-2 is-uppercase has-text-weight-bold has-text-primary">Vijay.</span>
                        </Link>
                        
                            {/*<img src="logo512.png"  width="75" height="64"></img>*/}
                        
                    </div>
                    <a role="button" className="navbar-burger has-text-primary" aria-label="menu" aria-expanded="false" data-target="navMenu" onClick={ToggleMenu}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div className="navbar-menu is-dark" id="navMenu">
                    
                    <div className="navbar-start">
                        {/*<a className="navbar-item is-size-4 has-text-primary">
                            Home
                        </a>*/}
                        <Link activeClass="active" className="navbar-item is-size-5 has-text-primary-light" to="about" spy={true} smooth={true} duration={500} offset={-70}>About</Link>
                        <Link activeClass="active" className="navbar-item is-size-5 has-text-primary-light" to="experience" spy={true} smooth={true} duration={500} offset={-70}>Experience</Link>
                        <Link activeClass="active" className="navbar-item is-size-5 has-text-primary-light" to="contact" spy={true} smooth={true} duration={500} offset={-70}>Contact</Link>
                    </div>
                    <div className="navbar-end">
                        <div className="navbar-item">                                
                            <p className="control">
                                <a className="button is-primary is-outlined" target="_blank" rel="noreferrer" ref={ref} href="CV-VijayTambe-JavaDeveloper.pdf">
                                    <span>Résumé</span>
                                </a>
                            </p>                                
                        </div>
                    </div>
                </div>
            </nav>            
        </header>
    )
}

export default Header;